import { Dispatch, SetStateAction } from 'react';

export enum ROUTE {
  HOME = 'home',
  MOBILE_PHONES = 'phones',
  POPULAR_PHONES = 'popular-phones',
  UPCOMING_PHONES = 'upcoming-phones',
  UPCOMING_TECHNOLOGIES = 'blog/category/upcoming-technologies',
  COMPARE = 'compare-phones',
  BLOG = 'blog',
  SEARCH = 'search',
  BRAND = 'brand',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS = 'terms',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us',
  ERROR_PAGE = '/404',
}

export enum BREADCRUMB {
  HOME = 'Home',
  MOBILE_PHONES = 'Mobile Phones',
  POPULAR_PHONES = 'Popular Phones',
  UPCOMING_PHONES = 'Upcoming Phones',
  COMPARE = 'Compare Phones',
  BLOG = 'blog',
  PRIVACY_POLICY = 'Privacy and Policy',
  TERMS = 'Terms and Conditions',
  ABOUT_US = 'About Us',
  CONTACT_US = 'Contact Us',
}

export enum Tag {
  phone = 'phone',
  watch = 'watch',
  tablet = 'tablet',
  upcoming = 'upcoming',
  popular = 'popular',
  featured = 'featured',
}
export enum storageKey {
  compareRouteLink = 'kcjEw9A3KqrffzhtYJoQ',
  brands = 'kUH74&*hfae&WDcjef&W9e',
}

export interface ContactUsType {
  data: {
    username: string;
    email: string;
    description: string;
  };
}

export interface LabelMap {
  [key: string]: string[];
}
export interface productDataInterface {
  id: number;
  productName: string;
  productImage: string;
  price: string;
  scoreTag: string;
  brandName: string;
  category: string;
}
export interface selectedBrand {
  brandName: string;
  isSelected: boolean;
}
export interface TextFieldObject {
  text: string;
  slug: string;
  isRemove: boolean;
  data: any;
  id: number | null;
}
export interface pagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}
export interface searchCompareText {
  text: string;
  slug: string;
  isRemove: boolean;
  data: never[];
  id: null;
  deviceName: string;
}
interface compareData {
  name: string;
}
interface compareItem {
  key: string;
  value: null | string | boolean | compareData[];
}

export interface compareTableData {
  key: string;
  data: compareItem[][];
}

export interface breadCrumbType {
  hrefLink: string;
  title: string;
  isActive: boolean;
}

export interface NextAppContextInterface {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  setSearchHeaderValue: Dispatch<SetStateAction<string>>;
  searchHeaderValue: string;
  setIsApiCall: Dispatch<SetStateAction<boolean>>;
  isApiCall: boolean;
  tagArr: { label: string; value: string | number; match: string | number }[];
  setTagArr: Dispatch<
    SetStateAction<
      { label: string; value: string | number; match: string | number }[]
    >
  >;
  comparisonSearch: any;
  setComparisonSearch: Dispatch<SetStateAction<any[]>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  isChange: boolean;
  setIsClickedOnCompare: Dispatch<SetStateAction<boolean>>;
  isClickedOnCompare: boolean;
  scrollTargetRef: HTMLDivElement | null | any;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  searchQuery: string;
  setBreadCrumbs: Dispatch<SetStateAction<breadCrumbType[] | undefined>>;
  breadCrumb: breadCrumbType[] | undefined;
  currencyDropDownValue: string;
  setCurrencyDropDownValue: Dispatch<SetStateAction<string>>;
  isCurrencyCodeDropDownChange: boolean;
  setCurrencyDropDownChange: Dispatch<SetStateAction<boolean>>;
  isSmallDevice: boolean;
  setIsSmallDevice: Dispatch<SetStateAction<boolean>>;
  deviceName: string[];
  setDeviceName: Dispatch<SetStateAction<string[]>>;
  deviceTitle: any;
  setDeviceTitle: any;
  isModelOpen: boolean;
  setIsModelOpen: Dispatch<SetStateAction<boolean>>;
}
export interface MetaDescriptionInterface {
  title?: string;
  description?: string;
  url?: string;
  ogUrl?: string;
  ogType?: string;
  imageURL?: string | any;
  isNotForHomePage?: boolean;
  isForSingleDevicePage?: boolean;
  robots?: string | undefined;
  keywords?: string;
  canonical?: string;
  country?: string;
}

export interface SeoInfo {
  canonicalURL: null | string;
  id: number;
  keywords: string;
  metaDescription: string;
  metaImage: null | string;
  metaRobots: string | null;
  metaTitle: string | null;
  metaViewport: string | null;
  structuredData: string | null;
}
export interface ImageData {
  alternativeText: string | null;
  caption: string | null;
  createdAt: string | null;
  ext: string | null;
  folderPath: string | null;
  hash: string | null;
  height: number | null;
  id: number | null;
  mime: string | null;
  name: string | null;
  previewUrl: string | null;
  provider: string | null;
  provider_metadata: string | null;
  size: number | null;
  updatedAt: string | null;
  url: string | null;
  width: number | null;
}

export interface ContactUsForm {
  username: string;
  email: string;
  description?: string;
}

export interface ContactUsFormError {
  invalidCaptchaToken: string;
  someThingWrong: string;
}

export enum DeviceTags {
  POPULAR_BADGE = 'popular_badge',
  UPCOMING_BADGE = 'upcoming_badge',
  DISCONTINUE_BADGE = 'discontinue_badge',
  RUMORED_BADGE = 'rumored_badge',
}

export enum DisplayTagNames {
  POPULAR = 'Popular',
  UPCOMING = 'Upcoming',
  DISCONTINUE = 'Discontinue',
  RUMORED = 'Rumored',
}

export enum priceEnum {
  US = 'USD',
  IN = 'INR',
  BR = 'BRL',
  CA = 'CAD',
  EU = 'EUR',
  GB = 'GBP',
}

// =======

export interface commonFields {
  id: number;
  device_name: string;
  slug: string;
  spec_score: string;
  price: any;
  main_image: ImageData;
  title: string;
  description: string;
  keywords: string;
  countryCode: string;
}
export interface metaPagination {
  pagination: {
    page: 0;
    pageSize: 0;
    pageCount: 0;
    total: 0;
  };
}

export interface apiResponse {
  status: number;
  data: object;
  meta: metaPagination;
}
export interface compareDevice {
  status: number;
  data: {
    data: Pick<
      commonFields,
      'id' | 'device_name' | 'slug' | 'spec_score' | 'price' | 'main_image'
    >[];
  };
}

export interface compareSingleDevice
  extends Pick<
    commonFields,
    'id' | 'device_name' | 'slug' | 'spec_score' | 'price' | 'main_image'
  > {
  device_type?: string;
  tags?: string[];
}
export interface upcomingPage {
  title: string;
  keywords: string;
  description: string;
  countryCode: string;
  singleData: singleDevice;
  id: string;
  // compareResult: compareDevice;
  compareResult: any;
}

export interface upcomingIdProps
  extends Pick<
    commonFields,
    'countryCode' | 'title' | 'description' | 'keywords'
  > {
  featurePhoneData: popularPhoneData;
  descriptionHeading: string;
  componentHeading: string;
}
export interface popularIdProps
  extends Pick<
    commonFields,
    'countryCode' | 'title' | 'description' | 'keywords'
  > {
  popularPhoneData: popularPhoneData;
  descriptionHeading: string;
  componentHeading: string;
}
export interface popularPhoneData {
  data: compareSingleDevice[];
  meta: metaPagination;
}
export interface filteredData {
  brand_name: [];
  battery_capacity: [];
  display_screen_size: {};
  price: {};
  internal_storage: [];
  primary_camera: [];
  secondary_camera: [];
  chipset_processor: [];
  resolution_type: [];
}
export interface searchResult {
  data: [];
  meta: metaPagination;
  filterData: filteredData;
}
export interface phonesIdProps {
  filterData: filteredData;
  propsProductData: searchResult;
  searchResult: searchResult;
  robots: string;
  searchParams: string;
  title: string;
  description: string;
  countryCode: string;
  allDeviceCount: any;
  brandDescription: string;
  brandHeading: string;
}

export interface compareDeviceProps {
  routePath: string;
  singleProductDetails: singleDevice;
  compareResult: any;
  compareText: any;
  url: string;
  countryCode: string;
  deviceNames: string[];
  deviceNameTitle: string[];
  compareSecondDevice: any;
}

export interface singleDevice
  extends Pick<
    commonFields,
    'id' | 'device_name' | 'slug' | 'spec_score' | 'price' | 'main_image'
  > {
  network_gprs: boolean;
  network_edge: boolean;
  launch_announced_year: number;
  launch_announced_month: number;
  launch_released_year: number;
  launch_released_month: number;
  launch_released_day: number;
  launch_announced_day: number;
  launch_status: string;
  body_weight: number;
  body_sim: string;
  body_build_glass_front_folded: null;
  body_build_glass_back: string;
  body_build_frame: string;
  body_notification_lights: null;
  body_blinking_indicator: null;
  body_ip_rating: null;
  body_keyboard: null;
  hdr_support: string;
  brightness: null;
  peak_brightness: null;
  display_screen_size: number;
  display_screen_area: number;
  display_screen_to_body_ratio: number;
  display_resolution_width: number;
  display_resolution_height: number;
  display_resolution_screen_ratio_numerator: number;
  display_resolution_screen_ratio_denominator: number;
  display_resolution_pixel_density: number;
  front_display_protection: null;
  back_display_protection: null;
  platform_os_type: string;
  platform_os_version: string;
  platform_os_upgradable_version: string;
  chipset_processor: string;
  chipset_model: string;
  chipset_manufacturing_technology: null;
  platform_gpu_series: string;
  platform_gpu_model: string;
  platform_gpu_core: null;
  memory_card_slot: string;
  memory_phonebook: null;
  memory_call_records: null;
  memory_storage_type: string;
  sound_loudspeaker_has_speakers: boolean;
  sound_loudspeaker_speaker_type: string;
  sound_jack_size: null;
  sound_jack_present: boolean;
  comms_bluetooth_version: string;
  comms_bluetooth_profile: null;
  comms_bluetooth_LE: boolean;
  comms_bluetooth_EDR: null;
  comms_bluetooth_support: null;
  comms_nfc: boolean;
  comms_radio: boolean;
  comms_usb_type: string;
  comms_infrared_port: boolean;
  battery_type: string;
  battery_capacity: number;
  battery_is_removable: boolean;
  battery_stand_by: null;
  battery_talk_time: null;
  battery_music_play: null;
  misc_sar_head: null;
  misc_sar_eu_head: null;
  misc_price: number;
  tests_display: null;
  tests_camera: null;
  tests_loud_speaker: null;
  tests_audio_quality: null;
  device_type: string;
  display_type: string;
  platform_os_ui: null;
  comms_bluetooth_A2DP: boolean;
  comms_bluetooth_APTX: null;
  comms_usb_otg: boolean;
  comms_usb_lighting: null;
  misc_sar_body: null;
  misc_sar_eu_body: null;
  test_battery_life: null;
  body_weight_oz: number;
  body_resistant: null;
  body_build_glass_front_unfolded: string;
  body_other: null;
  comms_wlan_wifiType: string;
  comms_wlan_dualBandEnabled: boolean;
  comms_wlan_wifiDirectEnabled: boolean;
  comms_wlan_hotspotEnabled: null;
  comms_wlan_dlnaEnabled: null;
  comms_wlan_upnpEnabled: null;
  device_information: null;
  page_title: null;
  page_description: null;
  brand_name: string;
  tags: string[];
  internal_storage: number;
  ram: number;
  display_refresh_rate: number;
  primary_camera: number;
  secondary_camera: number;
  resolution_type: string;
  descriptive_content: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  sitemap_exclude: boolean;
  cover_display_type: null;
  cover_display_screen_size: null;
  cover_display_resolution_width: null;
  cover_display_resolution_height: null;
  cover_display_glass: null;
  cover_display_ppi: null;
  main_camera_type: string;
  selfie_camera_type: string;
  display_AOD: boolean;
  comms_wlan_triBandEnabled: null;
  cover_display_resolution_pixel_density: null;
  cover_display_resolution_screen_ratio_numerator: null;
  cover_display_resolution_screen_ratio_denominator: null;
  cpu_core: number;
  device_score_weight: null;
  chipset_nanometer: number;
  comms_bluetooth_APTX_HD: boolean;
  comms_bluetooth_APTX_Adaptive: boolean;
  comms_bluetooth_APTX_Lossless: null;
  body_thickness: null;
  comms_bluetooth_LHDC: null;
  comms_nfc_other: null;
  comms_radio_other: null;
  feature_games: null;
  feature_java: null;
  cover_display_colors: null;
  cover_display_sound: null;
  cover_display_refresh_rate: null;
  cover_display_peak_brightness: null;
  cover_hdr_support: null;
  launch_expected_released_date: null;
  images: ImageData[];
  technology_name: TechnologyName[];
  network_2g_bands: NetworkBand[];
  network_3g_bands: NetworkBand[];
  network_4g_bands: NetworkBand[];
  network_5g_bands: NetworkBand[];
  network_speed: NetworkSpeed[];
  platform_cpu: PlatformCPU[];
  memory_internal: MemoryInternal[];
  main_camera_data: MainCameraData[];
  main_camera_video: MainCameraVideo[];
  main_camera_features: MainCameraFeature[];
  selfie_camera_data: SelfieCameraData[];
  selfie_camera_video: SelfieCameraVideo[];
  selfie_camera_features: SelfieCameraFeature[];
  charging_specifications: ChargingSpecification[];
  features_sensors: FeatureSensor[];
  device_features: any[];
  pricing_details: any[];
  tests_performance: TestPerformance[];
  comms_positioning: CommsPositioning[];
  body_dimensions: BodyDimension[];
  seo: SeoInfo;
  misc_models: MiscModel[];
  misc_colors: MiscColor[];
  brand: Brand;
  advertisement_links: any[];
  expert_review_links: ExpertReviewLink[];
  sound_other: any[];
  display_colors: DisplayColor[];
  features_other: any[];
  display_other: any[];
  cover_camera: any[];
  feature_browser: any[];
  feature_messaging: any[];
  performance: any[];
  shopping_links: affiliateLinks[];
  mobile_series: mobileSeries[];
}
export interface mobileSeries {
  id: number;
  series_name: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  sitemap_exclude: boolean;
}

export interface SingleDeviceMobileSeries
  extends Pick<commonFields, 'id' | 'slug' | 'device_name'> {
  main_image: {
    url: string;
  };
  launch_announced_year: number;
  launch_announced_month: number;
  launch_announced_day: number;
  launch_status: string;
}
export interface affiliateLinks extends Pick<commonFields, 'id'> {
  links: string;
  store: affiliateStore;
}
export interface affiliateStore extends Pick<commonFields, 'id'> {
  country: string;
  displayName: string;
  label: string;
  image: ImageData;
}

interface TechnologyName extends Pick<commonFields, 'id'> {
  name: string;
}

interface NetworkBand extends Pick<commonFields, 'id'> {
  bands: string | null;
  band_model: null | string;
  bandwidth: Bandwidth[];
}

interface Bandwidth extends Pick<commonFields, 'id'> {
  bandwidth: string;
}

interface NetworkSpeed extends Pick<commonFields, 'id'> {
  type: string;
  download_speed: null;
  upload_speed: null;
}

interface PlatformCPU extends Pick<commonFields, 'id'> {
  cores: string;
  frequency: string;
  architecture: string;
}

export interface MemoryInternal extends Pick<commonFields, 'id'> {
  price: number;
  memory_internal_storage: number;
  memory_internal_ram: number;
}

export interface CameraDataForFeatureBlock extends MainCameraData {
  frame_rate: string | null;
  mode: string | null;
}

export interface MainCameraData extends Pick<commonFields, 'id'> {
  aperture: string;
  autofocus: string | null;
  lense: string;
  resolution: number | null;
  pixel_size: string | null;
  sensor_size: string | null;
  image_stabalization: string | null;
}

export interface MainCameraVideo extends Pick<commonFields, 'id'> {
  frame_rate: string | null;
  mode: string | null;
  resolution: number | null;
}

interface MainCameraFeature extends Pick<commonFields, 'id'> {
  name: string;
}

interface SelfieCameraData extends Pick<commonFields, 'id'> {
  aperture: string;
  autofocus: null;
  lense: string;
  resolution: number;
  pixel_size: string;
  sensor_size: null;
  image_stabalization: null;
}

interface SelfieCameraVideo extends Pick<commonFields, 'id'> {
  frame_rate: string | null;
  mode: string | null;
  resolution: number | null;
}

interface SelfieCameraFeature extends Pick<commonFields, 'id'> {
  name: string;
}

export interface ChargingSpecification extends Pick<commonFields, 'id'> {
  charging_specifications: string;
  power_output: string;
  power_delivery: null;
  power_supply: null;
  quick_charge: null;
  advertised_charging_time: string;
}

interface FeatureSensor extends Pick<commonFields, 'id'> {
  name: string;
}

interface TestPerformance extends Pick<commonFields, 'id'> {
  tester_name: null;
  test_score: string;
  test_version: string;
}

interface CommsPositioning extends Pick<commonFields, 'id'> {
  satellite_navigation_system: string;
  frequency_bands: null;
}

interface BodyDimension extends Pick<commonFields, 'id'> {
  type: string;
  body_dimensions_height: null;
  body_dimensions_width: number;
  body_dimensions_depth: null;
  body_dimensions_height_inches: number;
  body_dimensions_width_inches: number;
  body_dimensions_depth_inches: null;
}

interface MiscModel extends Pick<commonFields, 'id'> {
  name: string;
}

interface MiscColor extends Pick<commonFields, 'id'> {
  name: string;
}

interface Brand extends Pick<commonFields, 'id'> {
  brand_name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  sitemap_exclude: null;
  brand_sequence: string;
  brand_score: number;
  image: ImageData;
}

interface ExpertReviewLink extends Pick<commonFields, 'id'> {
  link: string;
}

interface DisplayColor extends Pick<commonFields, 'id'> {
  name: string;
}
export interface HomePageBanner extends Pick<commonFields, 'id'> {
  isActive?: boolean;
  url?: string | undefined;
  index: number;
  image: {
    url: string;
    width: number;
    height: number;
  };
}

export interface sidebarFilterData {
  brand_name: [];
  battery_capacity: [];
  display_screen_size: [];
  price: [];
  internal_storage: [];
  primary_camera: [];
  secondary_camera: [];
  chipset_processor: [];
  resolution_type: [];
}
export interface productDataArray {
  slug: string;
  main_image: { url: string; alternativeText: string | null };
  price: {
    [key: string]: number | null;
  };
  url: string | null;
  device_name: string | null;
  device_type: string | null;
  spec_score: string | null;
}

export interface KeyAndValue {
  key: string;
  value: string;
}

export interface deviceSpecificationData {
  key: string;
  data: KeyAndValue[];
}

export interface HomePageProps {
  popular: popularPhoneData;
  featured: popularPhoneData;
  brandDevice: brandInformation[];
  locale: string;
  countryCode: string;
  homePageBanner: HomePageBanner[];
}
export interface brandInformation {
  brandName: string;
  slug: string;
  image: ImageData;
  seo: SeoInfo;
}

export interface blogAPI {
  title: {
    _text: string;
  };
  link: {
    _text: string;
  };
  comments: {
    _text: string;
  };
  'dc:creator': {
    _cdata: string;
  };
  pubDate: {
    _text: string;
  };
  category: {
    _cdata: string;
  };
  guid: {
    _attributes: {
      isPermaLink: string;
    };
    _text: string;
  };
  description: {
    _cdata: string;
  };
  'content:encoded': {
    _cdata: string;
  };
  'wfw:commentRss': {
    _text: string;
  };
  'slash:comments': {
    _text: string;
  };
}
export interface prosConsProps {
  text: string;
  slug: string;
  isRemove: boolean;
  data: singleDevice;
  id: number;
  deviceName: string;
}
