import Blogger from '../Assets/Images/shareImage/blogger.png';
import Email from '../Assets/Images/shareImage/email.png';
import Facebook from '../Assets/Images/shareImage/facebook.png';
import Linkedin from '../Assets/Images/shareImage/linkedin.png';
import Skype from '../Assets/Images/shareImage/skype.png';
import Twitter from '../Assets/Images/shareImage/twitter.png';
import WhatsApp from '../Assets/Images/shareImage/whatsApp.png';

export const ShareSvgData = [
  {
    Image: Blogger,
    value: 'blogger',
    link: 'https://www.blogger.com/blog-this.g?n=', //https://www.blogger.com/blog-this.g?u=https://example.com/page&n=Check%20out%20this%20page
  },
  {
    Image: Email,
    value: 'email',
    link: 'mailto:?subject=',
  }, //mailto:?subject=${subject}&body=${body}
  {
    Image: Facebook,
    value: 'facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u=', //"https://www.facebook.com/sharer/sharer.php?u=https://example.com/page
  },
  {
    Image: Linkedin,
    value: 'linkedin',
    link: 'https://www.linkedin.com/sharing/share-offsite/?url=', //https://www.linkedin.com/shareArticle?url=https://example.com/page&title=Check%20out%20this%20page
  },
  {
    Image: Skype,
    value: 'skype',
    link: 'https://web.skype.com/share?url=',
  }, //skype:username?chat&body=Check%20out%20this%20page:%20https://example.com/page
  {
    Image: Twitter,
    value: 'twitter',
    link: 'https://twitter.com/intent/tweet?url=', //https://twitter.com/intent/tweet?url=https://example.com/page&text=Check%20out%20this%20page
  },
  {
    Image: WhatsApp,
    value: 'whatsApp',
    link: 'https://api.whatsapp.com/send/?text=', //whatsapp://send?text=Check%20out%20this%20page:%20https://example.com/page
  },
];
export const featureArr = [
  'overview',
  'network',
  'launch',
  'display',
  'body',
  'main',
  'selfie',
  'platform',
  'battery',
  'storage',
  'comms',
  'sound',
  'performance',
  'sensors',
  'misc',
  'tests',
];
export const brandNameArr = [
  'brand_name',
  'internal_storage',
  'battery_capacity',
  'display_screen_size',
  'primary_camera',
  'secondary_camera',
  'chipset_processor',
  'resolution_type',
  'price',
];
//- USD- EURO- INR- GBP- BRL- CAD
export const currencyLocaleType: {
  [key: string]: { country: string; code: string; symbol: string };
} = {
  USD: { country: 'US', code: 'es-US', symbol: '$' },
  EUR: { country: 'EU', code: 'en-EU', symbol: '€' },
  INR: { country: 'IN', code: 'en-IN', symbol: '₹' },
  GBP: { country: 'GB', code: 'en-GB', symbol: '£' },
  BRL: { country: 'BR', code: 'pt-BR', symbol: 'R$' },
  CAD: { country: 'CA', code: 'en-CA', symbol: '$' },
};

export const redirectUrl = ['how-to-fix-5g-network-not-working-on-your-phone'];
