import { createContext, useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import { AppContext, AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import Theme from '@/Theme/theme';
import CreateEmotionCache from '@/Theme/createEmotionCache';
import '../styles/App.css';
import Layout from '@/Component/layout';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import {
  NextAppContextInterface,
  breadCrumbType,
} from '../Interface/interface';
import ErrorBoundary from './ErrorBoundary';
import { useRouter } from 'next/router';
import { removeTrailingSlashes } from '@/Configuration/Service';
import GoneComponent from '@/Component/errorPage410';
import Cookies from 'js-cookie';

export const Context = createContext<NextAppContextInterface>({
  setInputValue: () => {},
  inputValue: '',
  setSearchHeaderValue: () => {},
  searchHeaderValue: '',
  setIsApiCall: () => {},
  isApiCall: false,
  tagArr: [],
  setTagArr: () => {},
  comparisonSearch: [],
  setComparisonSearch: () => {},
  setIsChange: () => {},
  isChange: false,
  setIsClickedOnCompare: () => {},
  isClickedOnCompare: false,
  scrollTargetRef: null,
  setSearchQuery: () => {},
  searchQuery: '',
  setBreadCrumbs: () => {},
  breadCrumb: undefined,
  currencyDropDownValue: '',
  setCurrencyDropDownValue: () => {},
  isCurrencyCodeDropDownChange: false,
  setCurrencyDropDownChange: () => {},
  isSmallDevice: false,
  setIsSmallDevice: () => {},
  deviceName: [],
  setDeviceName: () => {},
  deviceTitle: [],
  setDeviceTitle: () => {},
  isModelOpen: false,
  setIsModelOpen: () => {},
});

const clientSideEmotionCache = CreateEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

type AppOwnProps = { renderGone: boolean };

export default function MyApp(props: MyAppProps & AppOwnProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    renderGone,
  } = props;
  if (renderGone) {
    return <GoneComponent />;
  }
  const router = useRouter();
  const [isSmallDevice, setIsSmallDevice] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isApiCall, setIsApiCall] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [isClickedOnCompare, setIsClickedOnCompare] = useState<boolean>(false);
  const [tagArr, setTagArr] = useState<
    { label: string; value: string | number; match: string | number }[]
  >([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [comparisonSearch, setComparisonSearch] = useState<any>([]);
  const [searchHeaderValue, setSearchHeaderValue] = useState('');
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);
  const [breadCrumb, setBreadCrumbs] = useState<breadCrumbType[]>();
  const [isCurrencyCodeDropDownChange, setCurrencyDropDownChange] =
    useState(false);
  const [currencyDropDownValue, setCurrencyDropDownValue] = useState<any>(
    Cookies.get('CountryCode') || 'US',
  );
  const [deviceName, setDeviceName] = useState<string[]>([]);
  const [deviceTitle, setDeviceTitle] = useState<string[]>([]);
  const [isModelOpen, setIsModelOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // identify small device for compareDevice page
      window.innerWidth <= 767
        ? setIsSmallDevice(true)
        : setIsSmallDevice(false);

      window.addEventListener('popstate', (event) => {
        {
          window?.location?.reload();
        }
      });
    }
  }, []);
  return (
    <Context.Provider
      value={{
        setInputValue,
        inputValue,
        setSearchHeaderValue,
        searchHeaderValue,
        setIsApiCall,
        isApiCall,
        tagArr,
        setTagArr,
        comparisonSearch,
        setComparisonSearch,
        setIsChange,
        isChange,
        setIsClickedOnCompare,
        isClickedOnCompare,
        scrollTargetRef,
        setSearchQuery,
        searchQuery,
        setBreadCrumbs,
        breadCrumb,
        currencyDropDownValue,
        setCurrencyDropDownValue,
        isCurrencyCodeDropDownChange,
        setCurrencyDropDownChange,
        isSmallDevice,
        setIsSmallDevice,
        deviceName,
        setDeviceName,
        deviceTitle,
        setDeviceTitle,
        isModelOpen,
        setIsModelOpen,
      }}
    >
      <CacheProvider value={emotionCache}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link rel="shortcut icon" href="/favicon.png" />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-CCD8M02J7Z"
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CCD8M02J7Z');
    `,
            }}
          ></script>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
                "@context" : "https://schema.org",
                "@type": "WebSite",
                "url": "${process.env.BASE_URL}",
                "inLanguage": "en",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target":"${process.env.BASE_URL!}?q={search_term}",
                  "query-input": "required name=search_term"
                }
              }`,
            }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `{
                "@context" : "https://schema.org",
                "@type": "WebPage",
                "description": "${pageProps?.description}",
                "url": "${removeTrailingSlashes(process.env.BASE_URL!)}${
                  router.asPath
                }",
                "publisher": {
                  "@type": "Organization",
                  "name": "TechSpecs.Info",
                  "url": "${process.env.BASE_URL}",
                  "logo": "${process.env.BASE_URL}favicon.png"
                },
                "name": "${pageProps?.title}"
              }`,
            }}
          />
        </Head>
        {/* <Script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6226712336518023"
          crossOrigin="anonymous"
        ></Script> */}
        <ThemeProvider theme={Theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Layout
            urlPath={pageProps?.routePath}
            singleDeviceData={
              pageProps?.result !== undefined ? pageProps?.result : ''
            }
            compareResult={pageProps?.compareResult}
            compareText={pageProps?.compareText}
            popularPhoneData={pageProps?.popularPhoneData}
          >
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </Layout>
        </ThemeProvider>
      </CacheProvider>
    </Context.Provider>
  );
}
